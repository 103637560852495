/* Reset Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Global Styles */
body {
  font-family: "Arial", sans-serif;
  line-height: 1.6;
  background-color: var(--primary-background);
  color: var(--text-primary);
  overflow-x: hidden; /* Prevents horizontal scrolling */
}

html {
  scroll-behavior: smooth; /* Enables smooth scrolling */
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  cursor: pointer;
  font-size: 1rem;
}
